import { AppRoute } from "./app-routes";
export default abstract class AppUtils {
  public static goBack = (navigation, navigationname ) => {console.log(navigationname)
    if (navigation.canGoBack())
      navigation.goBack();
    else if(navigationname!=undefined){
      navigation && navigation.navigate(navigationname);
      navigation.reset({
        index: 0,
        routes: [{ name: navigationname }]
      })
    }
    else{
      navigation && navigation.navigate(AppRoute.DASHBOARD);
      //navigation.popToTop();
      navigation.reset({
        index: 0,
        routes: [{ name: 'pages' }]
      })
    }
  }
}