import React, { ReactElement } from 'react';
import { GestureResponderEvent, TextInput, View, StyleSheet } from 'react-native';
import {
  Button,
  Datepicker,
  Divider,
  Icon,
  Input,
  Layout,
  StyleService,
  useStyleSheet,
  Text,

} from '@ui-kitten/components';
import { KeyboardAvoidingView } from './extra/3rd-party';
import { TouchableWithoutFeedback } from 'react-native';
import { Value, add, color, set, useValue } from 'react-native-reanimated';
import { CustomerViewModel } from 'src/model/customer.model';
import { useRoute } from '@react-navigation/native';
import { listSample } from '../customer-list';
import { Formik, FormikProps } from 'formik';
import { AppFetch } from '../../../services/app-fetch.service';
import Toast from 'react-native-root-toast';
const dataDetailSample =
{
  "clientData": [{
    "clientId": 0,
    "clientName": '',
    "address": '',
    "taxCode": '',
    "tag": 1,
    "contactName": '',
    "email": '',
    "note": '',
    "bankAccount": '',
    "openingBalance": 0,
    "clientCode": '',
    "phoneNumber": ' ',
    "saleInvoiceView": null
  }]
};

export default ({ navigation }): React.ReactElement => {
  const formikRef = React.useRef<FormikProps<any>>();
  const styles = useStyleSheet(themedStyles);
  const [model, setModel] = React.useState<CustomerViewModel | any>({...dataDetailSample});
  const route: any = useRoute();
  const { clientId } = route.params;
  const [ isDisables, setDisables]  = React.useState<boolean>(false);
  const [client, setClient] = React.useState<any>({...dataDetailSample.clientData[0]});
  const [value, setValue] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [captions, setCaptions] = React.useState(' ');
  const [toastt, setToastt] = React.useState(' ');
  // const DisabledInput = (id?) => {
  //   if(!id){id = clientId}
  //   if(id == 0) {return(false)}
  //   else return(true)
  // }

  const DisabledInput = (id?) => {
    if(!id){id = clientId}
    if(id == 0) {return(false)}
    else return(true)
  }  


  const loadData = (id?) => {
    if(!id) {
      id = clientId;
    }
    if(id == 0){
      AppFetch.get(`api/client/getNewestClientCode/`).then(rs => {
        if(rs.data!=undefined){
          client.clientCode = rs.data;
          setClient(client);
        }
      })
      return;
    }

    AppFetch.get(`Mobile/Client/${id}`).then(rs => {
      setClient(rs.data)
    });
  };

  React.useEffect(() => {
    loadData()
  }, [navigation,value]);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setClient({...dataDetailSample.clientData[0]});
      if (formikRef.current) {
        //formikRef.current?.setValues({...dataDetailSample.clientData[0]});
        formikRef.current?.setValues(client);
      }
    });
    return unsubscribe;
  }, [navigation]);
  
  const onSubmit = (client: CustomerViewModel, actions) => {
    console.log(status)
    if(isDisables == true) return;
    if(!client.clientName || 
       !client.clientCode || 
       !client.contactName
      ){
      Toast.show('Vui lòng nhập đầy đủ các trường cần thiết');
      return;
    }
    setDisables(true);
    AppFetch.post(`Mobile/Client`, client).then(rs => {
      setDisables(false);
      if(rs.success) {
        if(client.clientId == 0){
          actions.resetForm({ values:{...dataDetailSample.clientData[0]}});
        }
        else{
          loadData(rs.data.clientID);
        }
        Toast.show('Đã lưu');
        navigation.goBack();
      }
      else {
        Toast.show(rs.data);
      }
    });
  }

  return (
    <KeyboardAvoidingView style={styles.container}>
      <Formik
        innerRef={(f) => (formikRef.current = f)}
        enableReinitialize={true}
        initialValues={client}
        onSubmit={onSubmit}
        // validate={(values) =>
        //   new Promise(res => setTimeout(res, 2000)).then(() => {
        //     AppFetch.get(`Mobile/Client?keyword=${values.clientName}`).then(rs => {
        //       if (DisabledInput() == true) return;
        //       if(rs.data == null){
        //         setStatus('basic');
        //         setCaptions(' ');
        //         return;
        //       } else{ 
        //         for (let i = 0 ; i < rs.data.length; i++){
        //           if (values.clientName === rs.data[i].clientName){
        //             setStatus('danger');
        //             setCaptions('Tên khách hàng đã tồn tại.')
        //             return;
        //           }
        //         }
        //       }
        //       setStatus('basic');
        //       setCaptions(' ');
        //       return;
        //     });
        //   })
        // }
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <View style={{ flex: 1 }}>
            <Layout
              style={styles.form}
              level='1'
            >
              <Input
                style={styles.input}
                label='Khách hàng (*)'
                placeholder='Tên khách hàng'
                textStyle={{color:'rgb(34, 43, 69)'}}
                disabled={DisabledInput()}
                //status={status}
                //caption={evaProps => <Text style={{fontSize: 11,fontWeight: '400',fontFamily: 'opensans-regular',color: 'red',}}>{captions}</Text>}
                value={values.clientName}
                onChangeText={handleChange('clientName')}
                onBlur={handleBlur('clientName')}
              />
              <Input
                style={styles.input}
                label='Mã (*)'
                //disabled={DisabledInput()}
                disabled={true}
                textStyle={{color:'rgb(34, 43, 69)'}}
                placeholder=''
                value={values.clientCode}
                onChangeText={handleChange('clientCode')}
                onBlur={handleBlur('clientCode')}
              />
              <Input
                style={styles.input}
                label='Địa chỉ'
                placeholder=''
                value={values.address}
                onChangeText={handleChange('address')}
                onBlur={handleBlur('address')}
              />
              <View style={styles.middleContainer}>
                <View style={styles.middleInput}>
                  <Input
                    style={styles.input}
                    label='Người liên hệ (*)'
                    placeholder=''
                    value={values.contactName}
                    onChangeText={handleChange('contactName')}
                    onBlur={handleBlur('contactName')}
                  />
                </View>
                <View style={styles.middleInput}>
                  <Input
                    style={styles.input}
                    label='Email liên hệ'
                    placeholder=''
                    value={values.email}
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                  />
                </View>
              </View>
              <Input
                style={styles.input}
                label='Mã số thuế'
                placeholder=''
                value={values.taxCode}
                onChangeText={handleChange('taxCode')}
                onBlur={handleBlur('taxCode')}
              />
              <Input
                style={styles.input}
                label='Tài khoản ngân hàng'
                placeholder=''
                value={values.bankAccount}
                onChangeText={handleChange('bankAccount')}
                onBlur={handleBlur('bankAccount')}
              />
              <Input
                style={styles.input}
                label='Sô điện thoại'
                placeholder=''
                value={values.phoneNumber}
                onChangeText={handleChange('phoneNumber')}
                onBlur={handleBlur('phoneNumber')}
              />
              <Input
                style={styles.input}
                label='Số nợ đầu kì'
                placeholder=''
                value={values.openingBalance}
                onChangeText={handleChange('openingBalance')}
                onBlur={handleBlur('openingBalance')}
              />
              <Input
                style={styles.input}
                label='Ghi chú'
                multiline={true}
                textStyle={{minHeight: 64,}}
                // label={() => <Text style={{fontSize:12,fontWeight:'600'}}>Ghi chú</Text>}
                placeholder=''
                value={values.note}
                onChangeText={handleChange('note')}
                onBlur={handleBlur('note')}
              />
            </Layout>
            <View >
              <Button disabled={isDisables} onPress={handleSubmit}>Lưu</Button>
            </View>
          </View>
        )}
      </Formik>
      <Divider />
    </KeyboardAvoidingView>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  form: {
    flex: 1,
    paddingHorizontal: 4,
    paddingVertical: 24,
  },
  input: {
    marginHorizontal: 12,
    marginVertical: 4,

  },
  middleContainer: {
    flexDirection: 'row',
  },
  middleInput: {
    width: 128,
    flex: 1,
  },
  addButton: {
    marginHorizontal: 16,
    marginVertical: 24,
  },
});
