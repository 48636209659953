import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { CustomerListScreen } from '../scenes/customer/customer-list.component';
import { CustomerDetailScreen } from '../scenes/customer/customer-detail.component';
import { AppRoute } from '../app/app-routes';

const TopTab = createMaterialTopTabNavigator();
const Stack = createStackNavigator();


export const CustomerNavigator = (): React.ReactElement => (
  <Stack.Navigator headerMode='none'>
    <Stack.Screen name={AppRoute.CUSTOMER_LIST} component={CustomerListScreen}/>
    <Stack.Screen name={AppRoute.CUSTOMER_DETAIL} component={CustomerDetailScreen}/>
  </Stack.Navigator>
);
