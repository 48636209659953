export enum AppRoute {
  AUTH = 'Auth',
  SIGN_IN = 'SignIn2',
  HOME = 'pages',
  DASHBOARD = 'dashboard',
  THEME = 'Themes',
  ROOT = 'main',
  SALE_INVOICE = 'Sale-Invoice',
  SALE_INVOICE_LIST = 'Sale-Invoice-List',
  SALE_INVOICE_EDIT = 'Sale-Invoice-Edit',
  SALE_INVOICE_CREATE = 'Sale-Invoice-Create',
  CHAM_CONG_LIST = 'xem-cham-cong',
  CHAM_CONG_CREATE = 'tao-cham-cong',
  DUYET_CHAM_CONG_LIST = 'duyet-cham-cong',
  CUSTOMER_LIST = 'Customer-List',
  CUSTOMER_DETAIL = 'Customer-Detail',
}
