import { Divider, TopNavigation, TopNavigationAction } from '@ui-kitten/components';
import React from 'react';
import { ArrowIosBackIcon } from '../../components/icons';
import { SafeAreaLayout } from '../../components/safe-area-layout.component';
import ContentView from '../../layouts/customer/customer-list';
import AppUtils from '../../app/app-utils';
import { AppRoute } from '../../app/app-routes';

export const CustomerListScreen = ({ navigation }): React.ReactElement => {

  const renderBackAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={ArrowIosBackIcon}
      onPress={() => AppUtils.goBack(navigation, AppRoute.DASHBOARD)}
    />
  );

  return (<SafeAreaLayout style={{ flexDirection: 'column', flex: 1 }}
  insets='top'>
  <TopNavigation
    title='Khách hàng'
    accessoryLeft={renderBackAction}
  />
  <Divider />
  <ContentView navigation={navigation} range={undefined} keyword={''} />
</SafeAreaLayout>
);
}
