import React, { useCallback, useMemo, useRef } from 'react';
import { ListRenderItemInfo, StyleSheet, View, ScrollView, Dimensions } from 'react-native';
import { Card, CardElement, Layout, List, ListElement, ListProps, Text, Icon } from '@ui-kitten/components';
import Animated, { Extrapolate, interpolate } from 'react-native-reanimated';
import { SaleInvoiceListViewModel } from '../../../../model/sale-invoice.model';
import moment from 'moment';

export interface LayoutListProps extends Omit<ListProps, 'renderItem'> {
  data: SaleInvoiceListViewModel[];
  onItemPress: (index: number) => void;
  onItemLongPress?: (index: number) => void;
}

export type LayoutListElement = React.ReactElement<LayoutListProps>;

export const ClientList = (props: LayoutListProps): ListElement => {

  const { contentContainerStyle, onItemPress, onItemLongPress, ...listProps } = props;

  const renderItem = (info: ListRenderItemInfo<SaleInvoiceListViewModel>): CardElement => (
    <Card
      key={info.item.invoiceId}
      style={styles.itemContainer}
      onPress={() => onItemPress(info.index)}
      onLongPress={() => { onItemLongPress(info.index); }}>
      <Layout style={{ ...styles.layoutContainer, marginVertical: -5,marginHorizontal:-10}}>
        <View style={{ flex:1, marginHorizontal: 'auto',marginVertical:'auto' }}>
          <Icon
            style={styles.icon}
            fill='#8F9BB3'
            name='person-outline'
          />
        </View>
        <View style={{ flex:9, marginLeft:10 }}>
          <View style={{ ...styles.layout, flex:3 }}>
            <Text ellipsizeMode='tail' numberOfLines={1}category='s2'>Sđt: {info.item.phoneNumber} </Text>
          </View>
          <View style={{ ...styles.layout, flex:3 }}>
            <Text ellipsizeMode='tail' numberOfLines={1}
              category='s2'>
              {info.item.clientName}
            </Text>
          </View>
          <View style={{ ...styles.layout, flex:3 }}>
            <Text ellipsizeMode='tail' numberOfLines={1}
              category='s2' appearance='hint'>
              Đc: {info.item.address}
            </Text>
          </View>
        </View>
      </Layout>
    </Card>
  );

  return (
    <ScrollView style={{ height: Dimensions.get('window').height - 150 }}>
      <List
        {...listProps}
        //contentContainerStyle={[styles.container]}
        renderItem={renderItem}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: -10,
  },
  itemContainer: {
    padding:-10,
  },
  itemDescription: {
    marginTop: 2,
  },
  secondaryText: {
  },
  layoutContainer: {
    flexDirection: 'row',
  },
  layout: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  bottomSheetModal: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
    flexDirection: 'column',
  },
  bottomSheetModalItem: {
    flex: 1,
    width: '100%',
  },
  bottomSheetModalItemText: {
    textAlign: 'center'
  },
  icon: {
    maxWidth: 50,
    maxHeight: 50,
  },
});
