import { Button, CalendarRange, Divider, Input, Layout, ListProps, ModalService, Text } from '@ui-kitten/components';
import moment from 'moment';
import React, { useCallback, useMemo, useRef } from 'react';
import { SaleInvoiceListViewModel } from '../../../model/sale-invoice.model';
import { AppAuthService } from '../../../services/app-auth.service';
import { AppFetch } from '../../../services/app-fetch.service';
import { AppRoute } from '../../../app/app-routes';
import { LayoutList, LayoutListElement } from '../../../components/layout-list.component';
// import { data } from './extra/data';
import { SaleInvoiceList } from './extra/sale-invoice-list.component';
import Toast from 'react-native-root-toast';
import { Pressable, View } from 'react-native';

ModalService.setShouldUseTopInsets = true

export interface SaleInvoiceListProps {
  range: CalendarRange<Date>;
  keyword: string;
  navigation: any;
}


export default (props: SaleInvoiceListProps): LayoutListElement => {
  const { navigation, range, keyword } = props;
  const [data, setData] = React.useState<SaleInvoiceListViewModel | any[]>([]);
  const loadData = () => {
    const { startDate, endDate } = range as CalendarRange<Date>;
    console.log(keyword)
    if (startDate && endDate) {
      const request = {
        keyword: keyword,
        startDate: moment(startDate).format('YYYY/MM/DD'),
        endDate: moment(endDate).format('YYYY/MM/DD'),
      };
      // AppFetch.post(`SaleInvoice/GetAllSaleInvoice`, request).then(rp => {
      //   setData(rp);
      // });
    }
    
    AppFetch.post('Order/GetList?isMobile=1', {}).then(rs => {
      const result = rs.data.filter(item => item.status == '1' || item.status == '2');
      setData(result);
    });
  }
  
  React.useEffect(() => {
    loadData();
  }, [range, keyword]);
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      loadData();
    });
    return unsubscribe;
  }, [navigation]);

  const onItemPress = (index: number): void => {
    navigation.navigate(AppRoute.SALE_INVOICE_EDIT, { invoiceId: data[index].invoiceId });
  };

  let modalID = '';
  const onItemLongPress = (index: number): void => {
    // navigation.navigate(data[index].route);
    const contentElement = renderModalContentElement(index);
    modalID = ModalService.show(contentElement, { onBackdropPress: hideModal, backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } });
  };

  const hideModal = () => {
    ModalService.hide(modalID);
  };

  const renderModalContentElement = (index) => {
    return (
      <Pressable onPress={hideModal} style={{ flex: 1, justifyContent: 'flex-end' }}>
        <Layout style={{ flex: 1, justifyContent: 'flex-end', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <Layout level='4'>
          <View>
            <Text style={{textAlign: 'center', paddingVertical: 10}} 
            category='s2'>Đổi trạng thái</Text>
            <Button
              onPress={() => onPressTrangThai(index, OrderLongPressType.NotApprovedYet)}
              status='warning'>
              Chưa duyệt
            </Button>
            <Button
              onPress={() => onPressTrangThai(index, OrderLongPressType.Approved)}
              status='success'>
              Duyệt
            </Button>
          </View>
          <View>
            <Divider appearance='danger' />
            <Text style={{textAlign: 'center', paddingVertical: 10}}
            category='s2'>Chỉnh sửa</Text>
            <Button
              onPress={() => onPressTrangThai(index, OrderLongPressType.Delete)}
              status='danger'>
              Xóa
            </Button>
            <Button
              onPress={() => onPressTrangThai(index, OrderLongPressType.Cancel)}
              status='warning'
              appearance='outline'>
              Hủy
            </Button>
          </View>
          </Layout>
        </Layout>
      </Pressable>
    );
  };

  const onPressTrangThai = async (index, trangThai: OrderLongPressType) => {
    hideModal();
    const item = data[index];
    /**
         * Set Data
         */
    const request = { invoiceId: item.invoiceId };
    if (trangThai == OrderLongPressType.Delete) {
      /**
       * Request
       */
      AppFetch.delete(`Order/${request.invoiceId}`).then(rp => {
        Toast.show('Đã xóa');
        loadData();
      });

    } else if (trangThai == OrderLongPressType.NotApprovedYet) {
      /**
       * Request
       */
      AppFetch.post(`Order/${request.invoiceId}/Status/1`, {}).then(rp => {
        Toast.show('Đã thay đổi');
        loadData();
      });

    } else if (trangThai == OrderLongPressType.Approved) {
      /**
       * Request
       */
      AppFetch.post(`Order/${request.invoiceId}/Status/2`, {}).then(rp => {
        Toast.show('Đã thay đổi');
        loadData();
      });

    }
  }
  const onPressThemMoi = () => {
    navigation.navigate(AppRoute.SALE_INVOICE_CREATE);
  }

  return (
    <Layout level='1' style={{ flex: 1 }}>
      <SaleInvoiceList
        data={data}
        onItemPress={onItemPress}
        onItemLongPress={onItemLongPress}
      />
      <View>
        <Button onPress={onPressThemMoi}>Thêm mới</Button>
      </View>
    </Layout>
  );
};
enum OrderLongPressType {
  Delete,
  NotApprovedYet,
  Approved,
  Cancel,
}