import React, { useCallback, useMemo, useRef } from 'react';
import { Card, CardElement, Layout, List, ListElement, ListProps, Text } from '@ui-kitten/components';
export const StatusLabel = ({ status }) => {
  let statusTheme = '';
  let text = '';
  switch (status) {
    case '1':
      statusTheme = 'warning';
      text = 'Chưa duyệt';
      break;
    case '2':
      statusTheme = 'success';
      text = 'Đã duyệt';
      break;
    case '3':
      statusTheme = 'primary';
      text = 'Đã tạo đơn';
      break;
    case '4':
      statusTheme = 'info';
      text = 'Đã thu tiền';
      break;
    case '5':
      statusTheme = 'basic';
      text = 'Hoàn thành';
      break;
  }
  return (<Text category='s2' appearance='hint' status={statusTheme}>{text}</Text>);
}