import { Button, CalendarRange, Input, Layout, ListProps, ModalService, Text } from '@ui-kitten/components';
import moment from 'moment';
import React, { useCallback, useMemo, useRef } from 'react';
import { CustomerViewModel } from '../../../model/customer.model';
import { AppAuthService } from '../../../services/app-auth.service';
import { AppFetch } from '../../../services/app-fetch.service';
import { AppRoute } from '../../../app/app-routes';
import { LayoutList, LayoutListElement } from '../../../components/layout-list.component';
import { ClientList } from './extra/client-list.component';
import Toast from 'react-native-root-toast';
import { Pressable, View } from 'react-native';

ModalService.setShouldUseTopInsets = true

export interface CustomerViewProps {
  range: CalendarRange<Date>;
  keyword: string;
  navigation: any;
}

export default (props: any): LayoutListElement => {
  const { navigation } = props;
  const [data, setData] = React.useState<CustomerViewModel | any[]>([]);

  const [value, setValue] = React.useState('');

  const loadData = () => {
    AppFetch.get(`Mobile/Client?keyword=${value}`).then(rs => {
      if (rs && rs.data) {
        setData(rs.data);
      }
    });
  }
  React.useEffect(() => {
    loadData();
  }, [value]);
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      loadData();
    });

    return unsubscribe;
  }, [navigation]);

  
  const onItemPress = (index: number): void => {
    navigation.navigate(AppRoute.CUSTOMER_DETAIL, {clientId: data[index].clientID  });
  };
  const onPressThemMoi = () => {
    navigation.navigate(AppRoute.CUSTOMER_DETAIL, {clientId: 0  });
  }
  

  return (
    <Layout level='1' style={{ }}>
      <Input
          placeholder='Tìm kiếm'
          value={value}
          onChangeText={nextValue => setValue(nextValue)}
      />
      <ClientList
          data={data}
          onItemPress={onItemPress}
      />
      <View>
        <Button onPress={onPressThemMoi}>Thêm mới</Button>
      </View>
    </Layout>
  );
};