import React, { useCallback, useMemo, useRef } from 'react';
import { ListRenderItemInfo, StyleSheet, View, ScrollView, Dimensions } from 'react-native';
import { Card, CardElement, Layout, List, ListElement, ListProps, Text } from '@ui-kitten/components';
import Animated, { Extrapolate, interpolate } from 'react-native-reanimated';
import { SaleInvoiceListViewModel } from '../../../../model/sale-invoice.model';
import moment from 'moment';
import { StatusLabel } from './status-label.component';

export interface LayoutListProps extends Omit<ListProps, 'renderItem'> {
  data: SaleInvoiceListViewModel[];
  onItemPress: (index: number) => void;
  onItemLongPress?: (index: number) => void;
}

export type LayoutListElement = React.ReactElement<LayoutListProps>;

export const SaleInvoiceList = (props: LayoutListProps): ListElement => {

  const { contentContainerStyle, onItemPress, onItemLongPress, ...listProps } = props;

  const renderItem = (info: ListRenderItemInfo<SaleInvoiceListViewModel>): CardElement => (
    <Card
      key={info.item.invoiceId}
      style={{ ...styles.itemContainer, opacity: info.item.status == '2' || info.item.status == '3' || info.item.status == '4' || info.item.status == '5' ? 0.5 : 1 }}
      disabled={info.item.status == '2' || info.item.status == '3' || info.item.status == '4' || info.item.status == '5'}
      activeOpacity={1}
      onPress={() => onItemPress(info.index)}
      onLongPress={() => { onItemLongPress(info.index); }}>
      {/* Row 1 */}
      <Layout style={styles.layoutContainer}>
        <Layout style={{ ...styles.layout, flex: 4 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='s1'>
            Số: {info.item.invoiceNumber}
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, flex: 3, alignItems: 'flex-end' }} level='1'>
          <Text
            category='s2'
            numberOfLines={1}>
            Tổng tiền:
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, flex: 3, alignItems: 'flex-end' }} level='1'>
          <Text
            category='s2'
            numberOfLines={1}
            status='primary'>
            {info.item.amount?.toLocaleString('en-US')}
          </Text>
        </Layout>
      </Layout>

      {/* Row 2 */}
      <Layout style={{ ...styles.layoutContainer, marginVertical: 5, flexDirection: 'row' }}>
        <Layout style={{ ...styles.layout, flex: 5 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='s2'>
            {info.item.clientName}
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, flex: 2, alignItems: 'flex-end' }} level='1'>
          <Text
            category='s2' appearance='hint'>
            {moment(info.item.issueDate).format('DD/MM/YYYY')}
          </Text>
        </Layout>
      </Layout>

      {/* Row 3 */}
      <Layout style={{ ...styles.layoutContainer, marginBottom: 5, flexDirection: 'row' }}>
        <Layout style={{ ...styles.layout, flex: 4 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='s1'>
            {info.item.contactName}
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, flex: 3, alignItems: 'flex-end' }} level='1'>
          <Text
            category='s2'
            numberOfLines={1}>
            {info.item.phoneNumber}
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, flex: 3, alignItems: 'flex-end' }} level='1'>
          <Text
            category='s2'
            numberOfLines={1}
            status='primary'>
            {info.item.employeeName}
          </Text>
        </Layout>
      </Layout>

      {/* Row 4 */}
      <Layout style={{ ...styles.layoutContainer, marginBottom: 5, flexDirection: 'row' }}>
        <Layout style={{ ...styles.layout, flex: 3 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='s2' appearance='hint'>
            Đc: {info.item.address}

          </Text>
        </Layout>
      </Layout>


      {/* Row 5 */}
      <Layout style={styles.layoutContainer}>
        <Layout style={{ ...styles.layout, flex: 5 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='s2' appearance='hint'>
            Ghi chú: {info.item.note}
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, flex: 2, alignItems: 'flex-end' }} level='1'>
          <StatusLabel status={info.item.status}></StatusLabel>
        </Layout>
      </Layout>
    </Card>
  );

  return (
    <ScrollView style={{ height: Dimensions.get('window').height - 200 }}>
      <List
        {...listProps}
        contentContainerStyle={[styles.container, contentContainerStyle]}
        renderItem={renderItem}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
    paddingVertical: 4,
  },
  itemContainer: {
    marginVertical: 4,
    marginHorizontal: 0,
  },
  itemDescription: {
    marginTop: 2,
  },
  secondaryText: {
  },
  layoutContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  layout: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  bottomSheetModal: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
    flexDirection: 'column',
  },
  bottomSheetModalItem: {
    flex: 1,
    width: '100%',
  },
  bottomSheetModalItemText: {
    textAlign: 'center'
  },
});
