import { BottomNavigation, BottomNavigationTab, Button, CalendarRange, Card, Divider, Layout, Modal, RangeCalendar, StyleService, TopNavigation, TopNavigationAction } from '@ui-kitten/components';
import React from 'react';
import { ArrowIosBackIcon, MenuIcon, SearchIcon } from '../../components/icons';
import { SafeAreaLayout } from '../../components/safe-area-layout.component';
import ContentView from '../../layouts/cham-cong/cham-cong-list';
import { Animated, Dimensions, ScrollView, StyleSheet, View, ViewStyle } from 'react-native';
import { BrandBottomNavigation } from '../../components/brand-bottom-navigation.component';
import { AppRoute } from '../../app/app-routes';
import moment from 'moment';

const useVisibilityAnimation = (visible: boolean): ViewStyle => {

  const animation = React.useRef<Animated.Value>(new Animated.Value(visible ? 1 : 0));

  React.useEffect(() => {
    Animated.timing(animation.current, {
      duration: 200,
      toValue: visible ? 1 : 0,
      useNativeDriver: true,
    }).start();
  }, [visible]);

  return {
    transform: [
      {
        // @ts-ignore
        translateY: animation.current.interpolate({
          inputRange: [0, 1],
          outputRange: [50, 0],
        }),
      },
    ],
    position: visible ? null : 'absolute',
  };
};
const defaultRange:CalendarRange<Date> = {
  startDate: moment().subtract(3, 'days').toDate(),
  endDate: moment().add(1, 'days').toDate(),
};
export const ChamCongListScreen = ({ navigation }): React.ReactElement => {
  const transforms = useVisibilityAnimation(true);
  const [visible, setVisible] = React.useState(false);
  const [range, setRange] = React.useState<CalendarRange<Date>>(defaultRange);

  const renderBackAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={ArrowIosBackIcon}
      onPress={navigation.goBack}
    />
  );

  const renderRightAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={SearchIcon}
      onPress={() => { setVisible(true) }}
    />
  );
  console.log(range);

  return <SafeAreaLayout style={{ flexDirection: 'column', flex: 1 }}
    insets='top'>
    <TopNavigation
      title='Xem chấm công '
      accessoryLeft={renderBackAction}
      accessoryRight={renderRightAction}
    />
    <Modal visible={visible}
      backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      onBackdropPress={() => {setVisible(false); setRange({})}}>
      <Card>
        <RangeCalendar
          range={range}
          onSelect={nextRange => setRange(nextRange)}
          min={new Date(1900, 0, 0)}
          max={new Date(2050, 0, 0)}
        />
        <Button onPress={() => setVisible(false)}>
          Chọn
        </Button>
      </Card>
    </Modal>
    <Divider />
    <View style={{ flex: 1 }}>
      <ScrollView style={{ height: Dimensions.get('window').height - 200 }}>
        <ContentView navigation={navigation} range={range} />
      </ScrollView>
    </View>
    <View style={{ height: 100, justifyContent: 'center' }}>
      <Button
        size='giant'
        style={{
          marginHorizontal: 16,
          marginVertical: 24,
        }}
        onPress={() => { navigation.navigate(AppRoute.CHAM_CONG_CREATE); }}>
        Chấm công
      </Button>
    </View>
  </SafeAreaLayout>
};

const styles = StyleService.create({
  container: {
    left: 0,
    right: 0,
    bottom: 0,
    height: 100,
  },
});

