import React from 'react';
import { ImageStyle } from 'react-native';
import { ThemedIcon } from '../../components/themed-icon.component';
import {
  AssetArticlesDarkIcon,
  AssetArticlesIcon,
  AssetAuthDarkIcon,
  AssetAuthIcon,
  AssetCardDarkIcon,
  AssetCardIcon,
  AssetDrawerIcon,
  AssetDrawerDarkIcon,
  AssetDashboardsDarkIcon,
  AssetDashboardsIcon,
  AssetEcommerceDarkIcon,
  AssetEcommerceIcon,
  AssetMessagingDarkIcon,
  AssetMessagingIcon,
  AssetSocialDarkIcon,
  AssetSocialIcon,
} from '../../components/icons';
import { MenuItem } from '../../model/menu-item.model';
import { AppRoute } from '../../app/app-routes';

export interface LayoutData extends MenuItem {
  route: string;
  roleRequire: string[];
}

export const data: LayoutData[] = [
   {
    title: 'Đơn Hàng',
    route: AppRoute.SALE_INVOICE,
    roleRequire: [],
    icon: (style: ImageStyle) => {
      return React.createElement(
        ThemedIcon,
        { ...style, light: AssetCardIcon, dark: AssetCardDarkIcon },
      );
    },
  },
  {
   title: 'Khách Hàng',
   route: AppRoute.CUSTOMER_LIST,
   roleRequire: [],
   icon: (style: ImageStyle) => {
     return React.createElement(
       ThemedIcon,
       { ...style, light: AssetSocialIcon, dark: AssetArticlesDarkIcon },
     );
   },
 },
 {
  title: 'Mua hàng',
  route: AppRoute.SALE_INVOICE,
  roleRequire: [],
  icon: (style: ImageStyle) => {
    return React.createElement(
      ThemedIcon,
      { ...style, light: AssetEcommerceIcon, dark: AssetEcommerceDarkIcon },
    );
  },
},
{
 title: 'Kho',
 route: AppRoute.SALE_INVOICE,
 roleRequire: [],
 icon: (style: ImageStyle) => {
   return React.createElement(
     ThemedIcon,
     { ...style, light: AssetDrawerIcon, dark: AssetCardDarkIcon },
   );
 },
},
  // {
  //   title: 'Chấm Công',
  //   route: AppRoute.CHAM_CONG_LIST,
  //   roleRequire: [],
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetCardIcon, dark: AssetCardDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Duyệt',
  //   route: AppRoute.DUYET_CHAM_CONG_LIST,
  //   roleRequire: ['Duyệt Chấm Công'],
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetArticlesIcon, dark: AssetArticlesDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Chấm Công',
  //   route: AppRoute.CHAM_CONG_LIST,
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetCardIcon, dark: AssetCardDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Duyệt',
  //   route: AppRoute.DUYET_CHAM_CONG_LIST,
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetCardIcon, dark: AssetCardDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Auth',
  //   route: 'Auth',
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetAuthIcon, dark: AssetAuthDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Social',
  //   route: 'Social',
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetSocialIcon, dark: AssetSocialDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Articles',
  //   route: 'Articles',
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetArticlesIcon, dark: AssetArticlesDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Messaging',
  //   route: 'Messaging',
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetMessagingIcon, dark: AssetMessagingDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Dashboards',
  //   route: 'Dashboards',
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetDashboardsIcon, dark: AssetDashboardsDarkIcon },
  //     );
  //   },
  // },
  // {
  //   title: 'Ecommerce',
  //   route: 'Ecommerce',
  //   icon: (style: ImageStyle) => {
  //     return React.createElement(
  //       ThemedIcon,
  //       { ...style, light: AssetEcommerceIcon, dark: AssetEcommerceDarkIcon },
  //     );
  //   },
  // },
];
