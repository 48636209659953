import { Button, CalendarRange, Card, Divider, Input, Modal, ModalService, RangeCalendar, RangeDatepicker, TopNavigation, TopNavigationAction } from '@ui-kitten/components';
import moment from 'moment';
import React from 'react';
import { View } from 'react-native';
import AppUtils from '../../app/app-utils';
import { ArrowIosBackIcon, SearchIcon } from '../../components/icons';
import { SafeAreaLayout } from '../../components/safe-area-layout.component';
import ContentView from '../../layouts/sale-invoice/sale-invoice-list';
import { AppRoute } from '../../app/app-routes';

const defaultRange: CalendarRange<Date> = {
  startDate: moment().subtract(3, 'days').toDate(),
  endDate: moment().add(1, 'days').toDate(),
};
export const SaleInvoiceListScreen = ({ navigation }): React.ReactElement => {
  const [range, setRange] = React.useState<CalendarRange<Date>>(defaultRange);
  const [keyword, setKeyword] = React.useState<string>('');
  console.log(range)
  const renderBackAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={ArrowIosBackIcon}
      onPress={() => AppUtils.goBack(navigation, AppRoute.DASHBOARD)}
    />
  );
  let modalID = '';
  const renderRightAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={SearchIcon}
      onPress={() => {
        const contentElement = <SaleInvoiceSearchForm onSubmit={onSubmit}></SaleInvoiceSearchForm>;
        modalID = ModalService.show(contentElement, { onBackdropPress: hideModal, backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } });
      }}
    />
  );
  const hideModal = () => {
    ModalService.hide(modalID);
  };
  const onSubmit = (_range, _keyword) => {
    setRange(_range);
    setKeyword(_keyword);
    hideModal();
  };

  return (
    <View style={{ flexDirection: 'column', flex: 1 }}>
      <SafeAreaLayout style={{ flexDirection: 'column', flex: 1 }}
        insets='top'>
        <TopNavigation
          title='Xem Order'
          accessoryLeft={renderBackAction}
          accessoryRight={renderRightAction}
        />
        <Divider />
        <ContentView navigation={navigation} range={range} keyword={keyword} />
      </SafeAreaLayout>
    </View>
  );
}

const SaleInvoiceSearchForm = ({ onSubmit }): React.ReactElement => {
  const [range, setRange] = React.useState<CalendarRange<Date>>(defaultRange);
  const [keyword, setKeyword] = React.useState<string>('');


  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'none' }}>
      <Card>
        <Input
          label='Tìm kiếm'
          value={keyword}
          onChangeText={setKeyword} />
        <RangeDatepicker
          label='Chọn ngày'
          range={range}
          onSelect={nextRange => setRange(nextRange)}
          min={new Date(1900, 0, 0)}
          max={new Date(2050, 0, 0)}
        />
        <Button style={{ marginTop: 20 }} onPress={() => onSubmit(range, keyword)}>
          Chọn
  </Button>
      </Card>
    </View>);
};
