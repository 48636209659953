import { Autocomplete, AutocompleteItem, Button, Datepicker, Divider, Input, Layout, ModalService, Text } from '@ui-kitten/components';
import React, { useCallback, useMemo, useRef } from 'react';
import { Pressable, View } from 'react-native';
import { AppFetch } from '../../../services/app-fetch.service';
import { LayoutList, LayoutListElement } from '../../../components/layout-list.component';
import { data } from './extra/data';
import { useRoute } from '@react-navigation/native';
import { SaleInvoiceViewModel } from '../../../model/sale-invoice.model';
import { StatusLabel } from '../sale-invoice-list/extra/status-label.component';
import moment from 'moment';
import { AppAuthService } from '../../../services/app-auth.service';
import Toast from 'react-native-root-toast';
import { AppRoute } from '../../../app/app-routes';


const filter = (item, query) => item.clientName.toLowerCase().includes(query.toLowerCase());

export default ({ navigation }): LayoutListElement => {
  const route: any = useRoute();
  // const { clientId } = route.params;
  const [model, setModel] = React.useState<SaleInvoiceViewModel | any>([]);
  const [clientName, setClientName] = React.useState(null);
  const [address, setAddress] = React.useState('');
  const [contactName, setContactName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [invoiceId, setInvoiceId] = React.useState(route.params ? route.params.invoiceId : 0);
  const [discRate, setDiscRate] = React.useState('0');
  const [note, setNote] = React.useState('');
  const [date, setDate] = React.useState(new Date());

  const [clientList, setClientList] = React.useState([]);
  const [clientListFiltered, setClientListFiltered] = React.useState([]);
  
  const loadClient = (term) => {
    AppFetch.post('Client/GetAllClientAsync', {clientKeyword: term}).then(rs => {
      setClientList(rs);
      setClientListFiltered(rs);
    });
  };
  React.useEffect(() => {
    loadClient('');
    if(invoiceId != 0) {
      AppFetch.get(`Order/${invoiceId}`).then((rs) => {
        setModel(rs?.data);
        setClientName(rs?.data?.clientName);
        setNote(rs?.data?.note);
        setDiscRate((rs?.data?.discRate + '').replace(/[^0-9]/g, ''));
        AppFetch.post(`Client/GetClientById/${rs?.data?.clientId}`, {}).then(client => {
          setClientName(client?.clientName);
          setAddress(client?.address);
          setPhoneNumber(client?.phoneNumber);
          setContactName(client?.contactName);
        });
      });
    }
  }, []);

  const onSelect = (index) => {
    setClientName(clientListFiltered[index].clientName);
    setAddress(clientListFiltered[index].address);
    setContactName(clientListFiltered[index].contactName);
    setPhoneNumber(clientListFiltered[index].phoneNumber);
  };

  const onChangeText = (query) => {
    loadClient(query);
    setClientName(query);
    // setClientListFiltered(clientList.filter(item => filter(item, query)));
  };


  const renderOption = (item, index) => (
    <AutocompleteItem
      key={index}
      title={item.clientName}
    />
  );
  const save = async () => {
    const client = clientList.find(x => x.clientName == clientName) || {};
    if(client // 👈 null and undefined check
    && Object.keys(client).length === 0
    && Object.getPrototypeOf(client) === Object.prototype) {
      Toast.show('Vui lòng chọn Khách hàng');
      return;
    }
    const request = {
      ...client,
      address,
      contactName,
      phoneNumber,
      note,
      employeeName: await AppAuthService.getName(),
      issueDate: date.toJSON(),
      dueDate: date.toJSON(),
      invoiceId: invoiceId,
      status: '1',
      amount: 0,
      invoiceNumber: '',
      discRate: discRate,
      orderDetails: []
    };
    if(invoiceId == 0) {
      AppFetch.post('Order', request).then(rs => {
        if(rs?.success) {
          Toast.show('Đã lưu');
          navigation.navigate(AppRoute.SALE_INVOICE_LIST);
        }
        
      });
    } else {
      AppFetch.post('Order/MobileUpdate', request).then(rs => {
        if(rs?.success) {
          Toast.show('Đã lưu');
          navigation.goBack();
          // navigation.navigate(AppRoute.SALE_INVOICE_LIST);
        }
        
      });
    }
  };
  const loadData = () => {
  };
  React.useEffect(() => {
    loadData()
  }, []);

  return (
    <Layout level='3' style={{ flex: 1 }}>
      <View style={{ flex: 1, borderRadius: 10, overflow: 'hidden' }}>
        <Layout style={{ flex: 1, padding: 10, height: 30 }}>
          <View style={{ marginBottom: 30 }}>
            <Layout style={{ marginBottom: 10 }}>
              <View style={{ marginBottom: 10 }}>
                <Text category='p2' style={{ flex: 1 }}>Khách hàng (*)</Text>
                <Autocomplete
                  size='small'
                  value={clientName}
                  onSelect={onSelect}
                  onChangeText={onChangeText}>
                  {clientListFiltered.map(renderOption)}
                </Autocomplete>
              </View>
              <View style={{ marginBottom: 10 }}>
                <Text category='p2' style={{ flex: 1 }}>Ngày</Text>
                <Datepicker
                  size='small'
                  date={date}
                  onSelect={nextDate => setDate(nextDate)}
                />
              </View>
              <View style={{ marginBottom: 10 }}>
                <Text category='p2' style={{ flex: 1 }}>Địa chỉ (*)</Text>
                <Input
                  size='small'
                  value={address}
                  disabled={true}
                  onChangeText={nextValue => setAddress(nextValue)}
                  style={{ flex: 1}}
                  textStyle={{color:'rgb(143, 155, 179)'}} />
              </View> 
              <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                <View style={{ flex: 1, marginRight: 10 }}>
                  <Text category='p2' style={{ flex: 1 }}>Người liên hệ</Text>
                  <Input
                    size='small'
                    value={contactName}
                    disabled={true}
                    onChangeText={nextValue => setContactName(nextValue)}
                    style={{ flex: 1 }}
                    textStyle={{color:'rgb(143, 155, 179)'}} />
                </View>
                <View style={{ flex: 1, marginLeft: 10 }}>
                  <Text category='p2' style={{ flex: 1 }}>Số điện thoại (*)</Text>
                  <Input
                    size='small'
                    value={phoneNumber}
                    disabled={true}
                    onChangeText={nextValue => setPhoneNumber(nextValue)}
                    style={{ flex: 1 }}
                    textStyle={{color:'rgb(143, 155, 179)'}} />
                </View>
              </View>
              <View style={{ marginBottom: 10 }}>
                <Text category='p2' style={{ flex: 1 }}>Ghi chú đơn hàng</Text>
                <Input
                  size='small'
                  value={note}
                  onChangeText={nextValue => setNote(nextValue)}
                  style={{ flex: 1 }} />
              </View>
              <View style={{ marginBottom: 10 }}>
                <Text category='p2' style={{ flex: 1 }}>Giảm giá</Text>
                <Input
                  size='small'
                  value={discRate.toString()}
                  onChangeText={nextValue => setDiscRate(nextValue.replace(/[^0-9]/g, ''))}
                  style={{ flex: 1 }} />
              </View>
            </Layout>
          </View>
        </Layout>
      </View>
      <View >
        <Button onPress={save}>Lưu</Button>
      </View>
    </Layout>
  );
};
