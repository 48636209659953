import React from 'react';
import { StyleSheet } from 'react-native';
import { Divider, TopNavigation, TopNavigationAction } from '@ui-kitten/components';
import { SafeAreaLayout } from '../../components/safe-area-layout.component';
import { MenuGridList } from '../../components/menu-grid-list.component';
import { MenuIcon } from '../../components/icons';
import { data, LayoutData } from './data';
import { AppAuthService } from '../../services/app-auth.service';

export const LayoutsScreen = (props): React.ReactElement => {
  const [functionList, setFunctionList] = React.useState<LayoutData[]>([]);
  const [name, setName] = React.useState<string>('');
  React.useEffect(() => {
    AppAuthService.getXbookUserInfo().then(info => {
      console.log(info?.role)
      let list = data;
      if(info?.role) {
        list = data.filter(item => item.roleRequire.length === 0 || item.roleRequire.some(role => info.role.includes(role)));
      }
      setFunctionList(list);
    });
    AppAuthService.getName().then(setName);
  }, []);

  const onItemPress = (index: number): void => {
    props.navigation.navigate(data[index].route);
  };

  const renderDrawerAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={MenuIcon}
      onPress={props.navigation.toggleDrawer}
    />
  );

  return (
    <SafeAreaLayout
      style={styles.safeArea}
      insets='top'>
      <TopNavigation
        title={name}
        accessoryLeft={renderDrawerAction}
      />
      <Divider/>
      <MenuGridList
        data={functionList}
        onItemPress={onItemPress}
      />
    </SafeAreaLayout>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
});
