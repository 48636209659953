import { Autocomplete, AutocompleteItem, Button, Divider, Icon, Input, Layout, ModalService, Select, SelectItem, Text } from '@ui-kitten/components';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Pressable, RefreshControl, ScrollView, View } from 'react-native';
import { AppFetch } from '../../../services/app-fetch.service';
import { LayoutList, LayoutListElement } from '../../../components/layout-list.component';
import { data } from './extra/data';
import { useRoute } from '@react-navigation/native';
import { SaleInvoiceViewModel } from '../../../model/sale-invoice.model';
import { StatusLabel } from '../sale-invoice-list/extra/status-label.component';
import moment from 'moment';
import { AppAuthService } from '../../../services/app-auth.service';
import Toast from 'react-native-root-toast';
import { listSample } from '../sale-invoice-list';

const filter = (item, query) => item.productName.toLowerCase().includes(query.toLowerCase());

export default ({ navigation }): LayoutListElement => {
  const [model, setModel] = React.useState<SaleInvoiceViewModel | any>({});;
  const [clientName, setClientName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [productName, setProductName] = React.useState(null);
  const [price, setPrice] = React.useState('0');
  const [qty, setQty] = React.useState('1');
  const [discountRate, setDiscountRate] = React.useState('0');
  const [unit, setUnit] = React.useState('');
  const [productId, setProductId] = React.useState(0);

  const [productList, setProductList] = React.useState([]);
  const [productListFiltered, setProductListFiltered] = React.useState([]);

  const onSelect = (index) => {
    setProductName(productListFiltered[index].productName);
    setPrice(productListFiltered[index].unitPrice.toString());
    setQty('1');
    setDiscountRate('0');
    setUnit(productListFiltered[index].unit);
    setProductId(productListFiltered[index].productId);
  };

  const onChangeText = (query) => {
    setProductListFiltered(productList.filter(item => filter(item, query)));
    setProductName(query);
  };

  const onAddItem = () => {
    if (productName == '') {
      return;
    }
    const detail = {
      "id": 0,
      "invoiceId": invoiceId,
      "productId": productId,
      "productName": productName,
      "qty": Number(qty),
      "unit": unit,
      "price": Number(price),
      "discountRate": Number(discountRate),
      "amount": Number(price) * Number(qty) - ( Number(discountRate) / 100) * (Number(price) * Number(qty)),
    };
    AppFetch.post(`Order/${invoiceId}/OrderDetail`, detail).then(rs => {
      if (rs.success) {
        loadData();
      }
      else {
        Toast.show(rs.data);
      }

    });

    setModel(model);
    setProductName('');
    setPrice('0');
    setQty('1');
    setDiscountRate('0');
  };
  const onRemoveItem = (invoiceId, detailId) => {
    AppFetch.delete(`Order/${invoiceId}/OrderDetail/${detailId}`).then(rs => {
      loadData();
      Toast.show('Đã xóa');
    });
  };


  const renderOption = (item, index) => (
    <AutocompleteItem
      key={index}
      title={item.productName}
    />
  );

  const route: any = useRoute();
  const { invoiceId } = route.params;
  const subTotal = model?.orderDetails?.map(x => x.amount).reduce((item, count) => item + count, 0);
  const loadData = () => {
    AppFetch.get(`Order/${invoiceId}`).then(rs => {
      setModel(rs?.data);
      setClientName('');
      AppFetch.post(`Client/GetClientById/${rs?.data?.clientId}`, {}).then(client => {
        setClientName(client?.clientName);
        setAddress(client?.address);
        setPhoneNumber(client?.phoneNumber);
      });
    });
    AppFetch.post(`Product/GetAllProductAsync`, {isGrid: true}).then(rs => {
      setProductList(rs);
      setProductListFiltered(rs);
    });
  };
  React.useEffect(() => {
    loadData()
  }, [navigation]);

  return (
    <Layout level='3' style={{ flex: 1, marginBottom: 10 }}>
      <Layout level='3' style={{ height: 35, flexDirection: 'row', marginHorizontal: 20, marginTop: 20 }}>
        <Text category='h5'>Đơn hàng {model?.invoiceNumber}</Text>
        <Text status='primary' category='s2' style={{ flex: 1, textAlign: 'right' }}>
          {moment(model?.issueDate).format('DD/MM/YYYY')}
        </Text>
      </Layout>
      <Layout level='3' style={{ height: 35, flexDirection: 'column', marginHorizontal: 20, marginTop: 0, marginBottom: 20 }}>
        <Text category='s2' style={{ flex: 4 }}>{clientName} {phoneNumber}</Text>
        <Text category='s2' style={{ flex: 4 }}>{address}</Text>
      </Layout>
      <View style={{ flex: 1, borderRadius: 10, overflow: 'hidden', marginHorizontal: 20 }}>
        <Layout style={{ flex: 1, padding: 10, height: 30 }}>
          <View style={{ marginBottom: 30 }}>
            {/* Row */}
            <Layout style={{ flexDirection: 'row', marginBottom: 10, alignItems: 'center', flex:8}}>
              <View
                style={{ flex: 12 }} >
                <Autocomplete
                  label={'Tên hàng: '}
                  size='small'
                  value={productName}
                  onSelect={onSelect}
                  onChangeText={onChangeText}>
                  {productListFiltered.map(renderOption)}
                </Autocomplete>
              </View>
              <View style={{flexDirection:'column', flex:3}}>
                <Text> </Text>
                <Text category='p2' style={{ flex: 3, textAlign: 'right' }} numberOfLines={1}>({unit})</Text>
              </View>
            </Layout>


            {/* Row */}
            <Layout style={{ flexDirection: 'row', marginBottom: 10, alignItems: 'center' }}>
              <View style={{ flexDirection: 'row',flex:12}}>
                <View style={{ flex: 5, marginRight:20 }}>
                  <Input
                    label={'Đơn giá:'}
                    value={new Intl.NumberFormat("en-US").format(price)}
                    selectTextOnFocus
                    keyboardType='numeric'
                    onChangeText={nextValue => setPrice(nextValue.replace(/[^0-9]/g, ''))}
                    size='small' />
                </View>
                <Input
                  label={'SL:'}
                  selectTextOnFocus
                  value={new Intl.NumberFormat("en-US").format(qty)}
                  onChangeText={nextValue => setQty(nextValue.replace(/[^0-9]/g, ''))}
                  style={{ flex: 3, marginRight:20 }}
                  size='small' />
                <Input
                  label={'% CK:'}
                  value={new Intl.NumberFormat("en-US").format(discountRate)}
                  onChangeText={nextValue => setDiscountRate(nextValue.replace(/[^0-9]/g, ''))}
                  style={{ flex: 3}}
                  size='small' />
              </View>
              <View style={{ flexDirection: 'row',flex:3}}>
                <View style={{ flex: 3, flexDirection: 'column',bottom: 0 }}>
                  <View style={{ flex: 1}}><Text> </Text></View>
                    <Button style={{ flex: 1,marginLeft:10,bottom:-2 }} status='success' size='tiny' onPress={onAddItem}>+</Button>
                </View>
              </View>
            </Layout>
          </View>
          <View>
            <Layout style={{ flexDirection: 'row', marginBottom: 10 }}>
              <Text status='primary' category='s2' style={{ flex: 10 }}>Tên hàng hoá(Mã)</Text>
              <Text status='primary' category='s2' style={{ flex: 4, textAlign: 'left' }}>SL</Text>
              <View style={{ flex: 3, marginRight: 10 }}>
                <Text status='primary' category='s2' style={{ textAlign: 'center' }} numberOfLines={1}>% CK</Text>
              </View>
              <View style={{ flex: 3, marginRight: 10 }}>
                <Text status='primary' category='s2' style={{ textAlign: 'right' }} numberOfLines={1}>T.Tiền</Text>
              </View>
              <Text status='primary' category='s2' style={{ flex: 2 }} numberOfLines={1}> </Text>
            </Layout>
            <Divider />
          </View>
          <View style={{ flex: 1 }}>
            <ScrollView>
              {model?.orderDetails?.map(item => (
                <View>
                  <Layout style={{ flexDirection: 'row', minHeight: 30 }}>
                    <Text category='p2' style={{ flex: 10 }} numberOfLines={1}>{item.productName}</Text>
                    <Text category='p2' style={{ flex: 4, textAlign: 'left' }}>{item.qty.toLocaleString('en-US')} ({item.unit})</Text>
                    <View style={{ flex: 3, marginRight: 10 }}>
                      <Text style={{ textAlign: 'center' }} category='p2' >{item?.discountRate?.toLocaleString('en-US')}</Text>
                    </View>
                    <View style={{ flex: 3, marginRight: 10 }}>
                      <Text style={{ textAlign: 'right' }} category='p2' >{item.amount.toLocaleString('en-US')}</Text>
                    </View>
                    <View style={{ flex: 2 }} >
                      <Button status='danger' size='tiny' accessoryLeft={(prop) => <Icon {...prop} name='trash-2' />} onPress={() => onRemoveItem(item.invoiceId, item.id)}></Button>
                    </View>
                  </Layout>
                  <Divider />
                </View>
              ))}
            </ScrollView>
          </View>
        </Layout>
      </View>
      <Layout level='3' style={{ height: 35, marginHorizontal: 20, padding: 10, flexDirection: 'row' }}>
        <Text status='primary' category='p2' style={{ flex: 1, textAlign: 'right' }}>
          Tổng tiền: {subTotal?.toLocaleString('en-US')}
        </Text>
      </Layout>
    </Layout>
  );
};

