import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AuthNavigator } from './auth.navigator';
import { SocialNavigator } from './social.navigator';
import { ArticlesNavigator } from './articles.navigator';
import { MessagingNavigator } from './messaging.navigator';
import { DashboardsNavigator } from './dashboards.navigator';
import { EcommerceNavigator } from './ecommerce.navigator';
import { LayoutsScreen } from '../scenes/layouts/layouts.component';
import { AppRoute } from '../app/app-routes';
import { SaleInvoiceNavigator } from './sale-invoice.navigator';
import { ChamCongNavigator } from './cham-cong.navigator';
import { DuyetChamCongNavigator } from './duyet-cham-cong.navigator';
import { CustomerNavigator } from './customer.navigator';

const Stack = createStackNavigator();

export const LayoutsNavigator = (): React.ReactElement => (
  <Stack.Navigator headerMode='none'>
    <Stack.Screen name={AppRoute.DASHBOARD} component={LayoutsScreen}/>
    <Stack.Screen name='Auth' component={AuthNavigator}/>
    <Stack.Screen name={AppRoute.SALE_INVOICE} component={SaleInvoiceNavigator}/>
    <Stack.Screen name={AppRoute.DUYET_CHAM_CONG_LIST} component={DuyetChamCongNavigator}/>
    <Stack.Screen name={AppRoute.CHAM_CONG_LIST} component={ChamCongNavigator}/>
    <Stack.Screen name={AppRoute.CUSTOMER_LIST} component={CustomerNavigator}/>

    {/* <Stack.Screen name='Social' component={SocialNavigator}/>
    <Stack.Screen name='Articles' component={ArticlesNavigator}/>
    <Stack.Screen name='Messaging' component={MessagingNavigator}/>
    <Stack.Screen name='Dashboards' component={DashboardsNavigator}/>
    <Stack.Screen name='Ecommerce' component={EcommerceNavigator}/> */}
  </Stack.Navigator>
);
