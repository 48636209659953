import { useRoute } from '@react-navigation/native';
import { Divider, TopNavigation, TopNavigationAction } from '@ui-kitten/components';
import React from 'react';
import { BackHandler } from 'react-native';
import { AppRoute } from '../../app/app-routes';
import AppUtils from '../../app/app-utils';
import { ArrowIosBackIcon, SettingsIcon } from '../../components/icons';
import { SafeAreaLayout } from '../../components/safe-area-layout.component';
import ContentView from '../../layouts/sale-invoice/sale-invoice-edit';

export const SaleInvoiceEditScreen = ({ navigation }): React.ReactElement => {
  const route: any = useRoute();
  const { invoiceId } = route.params;

  const renderBackAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={ArrowIosBackIcon}
      onPress={() => AppUtils.goBack(navigation, AppRoute.SALE_INVOICE_LIST)}
    />
  );
  const renderRightAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={SettingsIcon}
      onPress={() => navigation.navigate(AppRoute.SALE_INVOICE_CREATE, { invoiceId: invoiceId })}
    />
  );

  return (<SafeAreaLayout style={{ flexDirection: 'column', flex: 1 }}
  insets='top'>
  <TopNavigation
    title='Xem Order'
    accessoryLeft={renderBackAction}
    accessoryRight={renderRightAction}
  />
  <Divider />
  <ContentView navigation={navigation} />
</SafeAreaLayout>
);
}
