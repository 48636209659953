import React, { useCallback, useEffect } from 'react';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { HomeNavigator } from './home.navigator';
import { createStackNavigator } from '@react-navigation/stack';
import { AppRoute } from '../app/app-routes';
import { AuthNavigator } from './auth.navigator';
import { AuthorizeResult } from 'react-native-app-auth';
import { AppConst } from '../app/app-const';
import { data } from '../scenes/auth/data';
import { AppStorage } from '../services/app-storage.service';
import { Alert, Platform } from 'react-native';
import { AppAuthService } from '../services/app-auth.service';

/*
 * Navigation theming: https://reactnavigation.org/docs/en/next/themes.html
 */
const navigatorTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    // prevent layout blinking when performing navigation
    background: 'transparent',
  },
};
const Stack = createStackNavigator();

export const AppNavigator = (): React.ReactElement => {
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);
  AppAuthService.isLoggedIn().then(rs => {
    setIsLoggedIn(rs);
  });console.log(isLoggedIn)
  return <NavigationContainer
    linking={{ prefixes: ['com.xbookdongtam.auth://', 'http://localhost:19006', 'http:*.b24.vn'] }}
    theme={navigatorTheme}>
    <Stack.Navigator headerMode='none' initialRouteName={AppRoute.AUTH}>
      {
        !isLoggedIn ? 
          <>
            <Stack.Screen name={AppRoute.AUTH} component={AuthNavigator} />
            <Stack.Screen name={AppRoute.ROOT} component={HomeNavigator} />
          </> 
          : 
            <Stack.Screen name={AppRoute.ROOT} component={HomeNavigator} />
      }
       

    </Stack.Navigator>
  </NavigationContainer>
};
